<template>
    <setting-layout :title="$t('pages.profile.main.following')" :back="{ name: 'user.own.profile.view' }">
        <v-container class="pt-2">
                <v-row align="center" class="mt-4 item" v-for="(item, index) in following" :key="index">
                    <v-col cols="2">
                        <router-link :to="{name: 'otherUser.profile.viewSlug', params: {slug: item.slug, from: $route.name, id: $route.params.id}}">
                            <profile-image
                                :src="item.image"
                                :username="item.name"
                            />
                        </router-link>
                    </v-col>
                    <v-col cols="6">
                        <div class="d-flex align-center">
                            <router-link :to="{name: 'otherUser.profile.viewSlug', params: {slug: item.slug, from: $route.name, id: $route.params.id}}">
                                {{ item.name }}
                            </router-link>
                            <verified-icon
                                v-if="item.isVerify"
                                :height="16" :width="16" class="ml-1" />
                        </div>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="3">
                        <span class="unblock text-capitalize" @click="handleUnfollow(item.id, index)">{{ $t('dialogs.unfollow') }}</span>
                    </v-col>
                </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";

import {mapActions, mapState} from "vuex";

export default {
    name: "Following",
    components: {
        SettingLayout,
        ProfileImage,
        VerifiedIcon
    },
    computed: {
        ...mapState({
            following: state => state.user.user.following
        }),
    },
    methods: {
        ...mapActions(['unfollow', 'getUserFollowing']),
        handleUnfollow(id, num) {
            this.unfollow(id).then(()=>{
                this.$store.commit('UNFOLLOW', num)
            })
        }
    },
    async mounted() {
        await this.getUserFollowing();
    }
}
</script>

<style scoped>
.profile-image {
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    object-fit: cover;
}

.unblock {
    color: #FE4811;
    font-size: 0.9em;
}

.item {
    background: #1A1A1A;
    border-radius: 18px;
}
</style>
