<template>
	<svg :width="width" :height="height" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
  		<rect width="124" height="124" rx="62" fill="url(#paint0_linear_1_6)"/>
  		<path d="M106 52.355L74.0245 50.2528L61.9825 20L49.9404 50.2528L18 52.355L42.498 73.1563L34.4591 104.711L61.9825 87.3133L89.506 104.711L81.4671 73.1563L106 52.355Z" fill="white"/>
  		<defs>
			<linearGradient id="paint0_linear_1_6" x1="62" y1="-39.5" x2="63.1615" y2="124" gradientUnits="userSpaceOnUse">
				<stop stop-color="#FFC107"/>
				<stop offset="0.15" stop-color="#FFAB40"/>
				<stop offset="0.4" stop-color="#FF9100"/>
				<stop offset="1" stop-color="#FF3D00"/>
			</linearGradient>
  		</defs>
  	</svg>
</template>

<script>
export default {
	name: "VerifiedIcon",
	props: {
		height: {
			type: Number,
			default: 48
		},
		width: {
			type: Number,
			default: 48
		}
  	}
}
</script>
