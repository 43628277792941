<template>
    <div class="setting-layout">
        <v-row class="mt-13 top-text" no-gutters align="baseline">
            <v-col cols="1" class="ml-2 back-icon" @click="handleBack">
                <router-link :to="{}" class="linked">
                    <v-icon size="33">mdi-chevron-left</v-icon>
                </router-link>
            </v-col>
            <v-col class="ml-5 text-capitalize">
                {{ title }}
            </v-col>
            <slot name="title"></slot>
        </v-row>
        <slot/>
    </div>
</template>

<script>
export default {
    name: "SettingLayout",
    props: {
        title: {
            type: String
        },
        back: {
            type: Object,
            default: null,
        },
        handleBackByEmit: {
            type: Boolean
        }
    },
    methods: {
        handleBack() {
            if (this.handleBackByEmit) {
                return this.$emit('back');
            }
            if (this.back) {
                return this.$router.push(this.back)
            }
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
.setting-layout {
    background: black;
    width: 100%;
    min-height: 100vh;
}

.top-text {
    font-size: 1.7em;
    font-weight: 900;
}

.back-icon {
    font-size: 1.1em;
}
</style>
